import React, { FC, useEffect, useState } from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";

export interface NcInputNumberProps {
  className?: string;
  defaultValue?: number;
  min?: number;
  max?: number;
  onChange?: (value: number) => void;
  label?: string;
  desc?: string;
  orderMin?:number;
  increase?:number;
  soldout?:boolean;
}

const NcInputNumber: FC<NcInputNumberProps> = ({
  className = "w-full",
  defaultValue = 0,
  min = 0,
  orderMin = 0,
  increase = 1,
  soldout,
  max,
  onChange,
  label,
  desc,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleClickDecrement = () => {
    if (min >= value) return;
    if (value <= orderMin){
      setValue(0)
      onChange && onChange(0);
    }
    else{
      setValue((state) => {
        return state - increase;
      });
      onChange && onChange(value - increase);
    }
  };
  const handleClickIncrement = () => {
    if (max && max <= value) return;
    if (value < orderMin){
      setValue(orderMin)
      onChange && onChange(orderMin);
    }
    else{
      setValue((state) => {
        return state + increase;
      });
      onChange && onChange(value + increase);
    }
    
  };

  const renderLabel = () => {
    return (
      <div className="flex flex-col">
        <span className="font-semibold text-neutral-800 dark:text-neutral-200">
          {label}
        </span>
        {desc && (
          <span className="font-semibold text-neutral-500 dark:text-neutral-400">
            {desc}
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-NcInputNumber flex items-center justify-between space-x-5 ${className}`}
      data-nc-id="NcInputNumber"
    >
      {label && renderLabel()}
        {!soldout && 
        <div
        className={`nc-NcInputNumber flex items-center justify-between w-28 space-x-5`}
      >
        <button
        className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
        type="button"
        onClick={handleClickDecrement}
        disabled={min >= value || soldout}
      >
        <MinusIcon className="w-4 h-4" />
      </button>
      <span>{value}</span>
      <button
        className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
        type="button"
        onClick={handleClickIncrement}
        disabled={max ? (soldout || value >= max) : soldout}
      >
        <PlusIcon className="w-4 h-4" />
      </button>
      </div>
      }
        
      
    </div>
  );
};

export default NcInputNumber;
